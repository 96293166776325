import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    access_token:
      {
        access_token: localStorage.getItem("access_token"),
        session_start: localStorage.getItem("session_start"),
      } || null,
    nav: false,
    centro: {
      plan: 0,
      agenda: 30,
      route: "",
      image: "",
      centro: '',
      link: '',
      sd:null
    },
    profesional: {
      bloqueos: 0,
      caja: 0,
      informes: 0,
      recepcion: 0,
      callc: 0
    },
    alert: false,
    color: "success",
    text_alert: "",
  },
  mutations: {
    setDataSd(state, data) {
      state.centro.sd = data.sd;
    },
    setDataCenter(state, data) {
      state.centro.plan = data.plan;
      state.centro.agenda = data.agenda;
      state.centro.route = data.route_img;
      state.centro.image = data.image;
      state.centro.centro = data.centro
      state.centro.link = data.link
    },
    setDataProf(state, data) {
      state.profesional = data;
    },
    setToken(state, token) {
      state.access_token.access_token = token;
      state.access_token.session_start = atob(token).split("-_-")[1];
    },
    loadNav(state, value) {
      state.nav = value;
    },
    setAlert(state, value) {
      state.text_alert = value.text;
      state.alert = value.alert;
      state.color = value.color;

      setTimeout(() => {
        state.alert = false;
        state.text_alert = "";
      }, 4000);
    },
  },
  actions: {
    setDataSd(contex, data) {
      contex.commit("setDataSd", data);
    },
    setDataCenter(contex, data) {
      contex.commit("setDataCenter", data);
    },
    setDataProf(contex, data) {
      contex.commit("setDataProf", data);
    },
    setAlert(contex, data) {
      contex.commit("setAlert", data);
    },
    get_data(contex, objet) {
      const headers = {
        "access-rec": atob(contex.state.access_token.access_token).split(
          "-_-"
        )[0],
        SSREC: contex.state.access_token.session_start,
      };
      const params = objet.params;
      return new Promise((resolve, reject) => {
        axios.get(objet.route, { params, headers }).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            if (error.response.status == 401) {
              contex.dispatch("clearCache");
            } else {
              reject(error);
            }
          }
        );
      });
    },
    axios_get(contex, objet) {
      const headers = {
        "access-rec": atob(contex.state.access_token.access_token).split(
          "-_-"
        )[0],
        SSREC: contex.state.access_token.session_start,
      };
      return new Promise((resolve, reject) => {
        axios.get(objet.route, { headers }).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            if (error.response.status == 401) {
              contex.dispatch("clearCache");
            } else {
              reject(error);
            }
          }
        );
      });
    },
    axios_post(contex, objet) {
      const headers = {
        "access-rec": atob(contex.state.access_token.access_token).split(
          "-_-"
        )[0],
        SSREC: contex.state.access_token.session_start,
      };
      const data = JSON.stringify(objet.data);
      return new Promise((resolve, reject) => {
        axios.post(objet.route, data, { headers }).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            if (error.response.status == 401) {
              contex.dispatch("clearCache");
            } else {
              reject(error);
            }
          }
        );
      });
    },
    tokenValid(contex, permisos) {
      const headers = {
        "access-rec": atob(contex.state.access_token.access_token).split(
          "-_-"
        )[0],
        SSREC: contex.state.access_token.session_start,
      };
      const params = permisos;
      return new Promise((resolve, reject) => {
        axios.get("/token_valid_recepcion", { params, headers: headers }).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    setToken(contex, token) {
      return new Promise((resolve, reject) => {
        localStorage.setItem("access_token", btoa(token));
        localStorage.setItem("session_start", token.split("-_-")[1]);
        contex.commit("setToken", btoa(token));
        if (localStorage.getItem("access_token") != null) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    },
    loadNav(contex, value) {
      contex.commit("loadNav", value);
      return value;
    },
    logout(contex, token) {
      const headers = {
        "access-rec": atob(token.access_token).split("-_-")[0],
        SSREC: token.session_start,
      };
      axios.get("/logout_recepcion", { headers: headers }).then(
        (response) => {
          if (response.data.success) {
            contex.dispatch("clearCache");
          }
        },
        (error) => {
          if (errror.response.status == 401) {
            contex.dispatch("clearCache");
          }
        }
      );
    },
    login(contex, data) {
      return new Promise((resolve, reject) => {
        axios.post(data.route, JSON.stringify(data.data)).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    clearCache(contex) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("session_start");
      window.location.reload();
    },
  },
  modules: {},
  getters: {
    validation_form: () => (form) => {
      let obj = form.reduce((acum, value) => {
        if (value.id != undefined) {
          if (value.show) {
            acum[value.id] = value.model;
          }
        }
        return acum;
      }, {});
      return obj;
    },
  },
});
