<template>
  <div class="menu">
    <!-- Barra top -->
    <v-app-bar app fixed class="d-print-none">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ menu.user }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-autocomplete
        chips
        deletable-chips
        dense
        outlined
        rounded
        small-chips
        class="mt-6 mr-2"
        v-model="route"
        append-icon="mdi-magnify"
        :items="router_search"
        :filter="customFilter"
        item-text="item"
        item-value="path"
        label="Donde quieres ir..."
        @change="changeView(route)"
      ></v-autocomplete>
      <v-menu left bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout()">
            <v-list-item-title> Cerrar sesión </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!--  -->

    <!-- Barra lateral -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      dark
      app
      mobile-breakpoint="991"
      width="260"
      color="black"
      class="d-print-none"
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title> Recepción </v-list-item-title>
            <v-list-item-subtitle>
              {{ menu.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="mt-2">
          <v-card
            class="portrait"
            :img="$store.state.centro.route + menu.img"
            height="200"
            width="400"
          ></v-card>
        </v-list-item>
        <v-list-group
          v-for="(item, index) in menu_valid"
          :key="index"
          :value="index == 0"
          :prepend-icon="item.icon"
          color="white"
        >
          <template v-slot:activator>
            <v-list-item-title>
              {{ item.item }}
            </v-list-item-title>
          </template>
          <v-list-item
            v-for="(subitem, subindex) in item.items"
            :key="subindex"
            link
            @click="changeView(subitem.path)"
          >
            <v-list-item-content class="ml-4" v-if="subitem.path != '_menu'">
              <v-list-item-title style="color: #1976d2 !important">
                <strong>
                  {{ subitem.item }}
                </strong>
              </v-list-item-title>
            </v-list-item-content>
            <v-menu v-else offset-x top>
              <template v-slot:activator="{ on }">
                <v-list-item-content v-on="on" class="ml-4">
                  <v-list-item-title style="color: #1976d2 !important">
                    <strong>
                      {{ subitem.item }}
                    </strong>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item_menu, item_index) in subitem.subitems"
                  :key="item_index"
                  @click="changeView(item_menu.path)"
                >
                  <v-list-item-title>
                    {{ item_menu.item }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <!-- <v-snackbar :timeout="-1" :value="true" bottom color="warning" right>
      Acabamos de realizar una actualizacion, sobre agenda, por favor refresca
      tu plataforma presionando las teclas Ctrl + Shift + R
    </v-snackbar> -->
    <!--  -->
    <v-snackbar
      v-model="$store.state.alert"
      :color="$store.state.color"
      top
      right
    >
      {{ $store.state.text_alert }}
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "Menu",
  data() {
    return {
      drawer: true,
      route: null,
      menu: {
        name: "profesional",
        user: "Centro",
        img: "profile.png",
        menuOptions: [
          // Agenda
          {
            item: "Agenda",
            icon: "mdi-calendar",
            items: [
              {
                item: "Inicio",
                permiso: {
                  type: ["recepcion", "informes", "caja", "bloqueos", "callc"],
                  valid: false,
                },
                path: "/home",
              },
              {
                item: "Agenda",
                permiso: { type: ["recepcion"], valid: false },
                path: "/agenda",
              },
              {
                item: "Tomar Cita",
                permiso: { type: ["recepcion"], valid: false },
                path: "/tomarCita",
              },
              {
                item: "Agenda privada",
                permiso: { type: ["recepcion"], valid: false },
                path: "/privada",
              },
              {
                item: "Ventas",
                path: "_menu",
                permiso: null,
                subitems: [
                  {
                    item: "Otras ventas",
                    permiso: { type: ["recepcion"], valid: false },
                    path: "/otrasVentas",
                  },
                  {
                    item: "Ventas a crédito",
                    permiso: { type: ["recepcion"], valid: false },
                    path: "/credito",
                  },
                ],
              },
              {
                item: "Servicios",
                permiso: { type: ["caja"], valid: false },
                path: "/servicios",
              },
              {
                item: "Clientes",
                permiso: { type: ["recepcion"], valid: false },
                path: "/clientes",
              },
              {
                item: "Bloqueos",
                permiso: { type: ["bloqueos"], valid: false },
                path: "/bloqueos",
              },
              {
                item: "Bonos regalo",
                permiso: { type: ["recepcion"], valid: false },
                path: "/bonoRegalo",
              },
              {
                item: "Anticipos",
                permiso: { type: ["recepcion"], valid: false },
                path: "/anticipos",
              },
              {
                item: "Abonos por cobrar",
                permiso: { type: ["recepcion"], valid: false },
                path: "/abonosFacturas",
              },
            ],
          },
          // Caja
          {
            item: "Caja",
            icon: "mdi-cash-register",
            items: [
              {
                item: "Gastos",
                permiso: { type: ["recepcion", "informes"], valid: false },
                path: "/gastos",
              },
              {
                item: "Liquidación profesional",
                permiso: { type: ["recepcion", "informes"], valid: false },
                path: "/liquidacion",
              },
              {
                item: "Abonos a préstamos",
                permiso: { type: ["recepcion"], valid: false },
                path: "/abonoPrestamos",
              },
            ],
          },
          // Productos
          {
            item: "Productos",
            icon: "mdi-cart",
            items: [
              {
                item: "Productos",
                permiso: { type: ["caja"], valid: false },
                path: "/productos",
              },
              {
                item: "Movimientos en stock",
                permiso: { type: ["caja"], valid: false },
                path: "/movimientosStock",
              },
              {
                item: "Órdenes de compra",
                permiso: { type: ["informes"], valid: false },
                path: "/ordenes",
              },
              {
                item: "Abonos de Compras",
                permiso: { type: ["informes"], valid: false },
                path: "/abonosOrden",
              },
              {
                item: "Cierre de inventario",
                permiso: { type: ["informes"], valid: false },
                path: "/cierreInventarios",
              },
              {
                item: "Stock",
                path: "_menu",
                permiso: null,
                subitems: [
                  {
                    item: "De ventas",
                    permiso: { type: ["informes"], valid: false },
                    path: "/stockVentas",
                  },
                  {
                    item: "De consumos",
                    permiso: { type: ["informes"], valid: false },
                    path: "/stockConsumos",
                  },
                ],
              },
            ],
          },
          // Informes
          {
            item: "Informes",
            icon: "mdi-book-open-page-variant",
            items: [
              {
                item: "Cuadre de caja",
                path: "_menu",
                permiso: null,
                subitems: [
                  {
                    item: "Del día",
                    permiso: {
                      type: ["recepcion", "informes"],
                      valid: false,
                    },
                    path: "/cuadreCaja",
                  },
                  {
                    item: "Acumulado",
                    permiso: { type: ["informes"], valid: false },
                    path: "/cuadrecajaAc",
                  },
                ],
              },
              {
                item: "Facturas",
                permiso: { type: ["informes"], valid: false },
                path: "/facturas",
              },
              {
                item: "Cartera",
                permiso: { type: ["informes"], valid: false },
                path: "/facturasPendientes",
              },
              {
                item: "Clientes por visitar",
                permiso: { type: ["informes"], valid: false },
                path: "/visitaPendiente",
              },
              {
                item: "Documentos Electrónicos",
                permiso: { type: ["informes"], valid: false },
                path: "/documentos_electronicos",
              },
              {
                item: "Historial",
                path: "_menu",
                permiso: null,
                subitems: [
                  {
                    item: "De citas",
                    permiso: { type: ["informes"], valid: false },
                    path: "/historial_citas",
                  },
                  {
                    item: "De clientes",
                    permiso: { type: ["informes"], valid: false },
                    path: "/historial_clientes",
                  },
                  {
                    item: "De visitas programadas",
                    permiso: { type: ["informes"], valid: false },
                    path: "/historialVisitasClientes",
                  }
                ],
              },
              {
                item: "Productos",
                path: "_menu",
                permiso: null,
                subitems: [
                  {
                    item: "De ventas",
                    permiso: { type: ["informes"], valid: false },
                    path: "/informe_productos_venta",
                  },
                  {
                    item: "De consumos",
                    permiso: { type: ["informes"], valid: false },
                    path: "/informe_productos_consumo",
                  },
                  {
                    item: "Por producto",
                    permiso: { type: ["informes"], valid: false },
                    path: "/informe_por_producto",
                  },
                ],
              },
              {
                item: "Lista notas crédito",
                permiso: { type: ["informes"], valid: false },
                path: "/notas_credito",
              },
              {
                item: "Devoluciones",
                permiso: { type: ["informes"], valid: false },
                path: "/devoluciones",
              },
              {
                item: "Servicios",
                permiso: { type: ["informes"], valid: false },
                path: "/info-servicios",
              },
              {
                item: "Clientes",
                path: "_menu",
                permiso: null,
                subitems: [
                  // {
                  //   item: "Referidos",
                  //   permiso: { type: ["informes"], valid: false },
                  //   path: "/",
                  // },
                  {
                    item: "Frecuentes",
                    permiso: { type: ["informes"], valid: false },
                    path: "/info-frecuentes",
                  },
                ],
              },
              // {
              //   item: "Promedio de calificaciones",
              //   permiso: { type: ["informes"], valid: false },
              //   path: "/",
              // },
              {
                item: "Gráficas",
                permiso: { type: ["informes"], valid: false },
                path: "/graficas",
              },
              {
                item: "Vendedores",
                permiso: { type: ["informes"], valid: false },
                path: "/vendedores",
              },
              {
                item: "Puntos",
                permiso: { type: ["informes"], valid: false },
                path: "/puntos",
              },
              // {
              //   item: "Quejas y sugerencias",
              //   permiso: { type: ["informes"], valid: false },
              //   path: "/",
              // },
            ],
          },
          // Interacciones
          {
            item: "Interacciones",
            icon: "mdi-bell",
            items: [
              // {
              //   item: "Promociones tomadas",
              //   permiso: { type: ["recepcion", "informes"], valid: false },
              //   path: "/",
              // },
              {
                item: "Cumpleaños",
                permiso: { type: ["recepcion", "informes"], valid: false },
                path: "/cumple",
              },
            ],
          },
          // Cambio de sede
          {
            item: "Cambio de sede",
            icon: "mdi-autorenew",
            items: [
              {
                item: "Cambiar de sede",
                permiso: {
                  type: ["callc"],
                  valid: false,
                },
                path: "/cambio_sede",
              },
            ],
          },
          // Sesiones
          {
            item: "Sesiones",
            icon: "mdi-key",
            items: [
              {
                item: "Sesiones activas",
                permiso: {
                  type: ["recepcion", "informes", "bloqueos", "caja", "callc"],
                  valid: false,
                },
                path: "/sesiones",
              },
            ],
          },
          // Guias
          // {
          //   item: "Guias",
          //   icon: "mdi-play",
          //   items: [
          //     {
          //       item: "Guias",
          //       permiso: {
          //         type: ["recepcion", "informes", "bloqueos", "caja", "callc"],
          //         valid: false,
          //       },
          //       path: "/guias",
          //     },
          //   ],
          // },
        ],
      },
    };
  },
  methods: {
    loadData() {
      const body = {
        route: "/primary_data",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.menu.img = response.data.data.image;
            this.$store.dispatch("setDataCenter", {
              plan: response.data.data.plan,
              agenda: response.data.data.agenda,
              route_img: response.data.data.route,
              image: response.data.data.image,
              centro: response.data.data.centro,
              link: response.data.data.link,
            });
          }
        })
        .catch((error) => {
          this.logout();
        });
    },
    loadDataProf() {
      const body = {
        route: "/primary_data_prof",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.menu.name = response.data.data.profesional;
            this.menu.user = response.data.data.sede;
            this.$store.dispatch("setDataProf", response.data.data.permisos);
            this.$store.dispatch("setDataSd", {sd: response.data.data.sd});
          }
        })
        .catch((error) => {
          this.logout();
        });
    },
    customFilter(item, queryText, itemText) {
      if (item.item != undefined) {
        const textOne = item.item.toLowerCase();
        const searchText = queryText.toLowerCase();
        return textOne.indexOf(searchText) > -1;
      }
    },
    changeView(path) {
      switch (path) {
        case "_menu":
          break;
        case null:
          break;
        default:
          if (this.$route.path != path) this.$router.push(path);
          break;
      }
    },
    logout() {
      this.$store.dispatch("logout", this.$store.state.access_token);
    },
  },
  created() {
    this.loadData();
    this.loadDataProf();
  },
  computed: {
    menu_valid() {
      let routes = [];
      let rutas = this.menu.menuOptions.map((item) => {
        for (let subitem of item.items) {
          // Elementos sin valores heredados
          if (subitem.path != "_menu") {
            if (subitem.permiso != null) {
              // Validacion de permisos
              if (
                subitem.permiso.type.includes("recepcion") &&
                this.$store.state.profesional.recepcion
              ) {
                subitem.permiso.valid = true;
              }
              if (
                subitem.permiso.type.includes("informes") &&
                this.$store.state.profesional.informes
              ) {
                subitem.permiso.valid = true;
              }
              if (
                subitem.permiso.type.includes("bloqueos") &&
                this.$store.state.profesional.bloqueos
              ) {
                subitem.permiso.valid = true;
              }
              if (
                subitem.permiso.type.includes("caja") &&
                this.$store.state.profesional.caja
              ) {
                subitem.permiso.valid = true;
              }
              if (
                subitem.permiso.type.includes("callc") &&
                this.$store.state.profesional.callc
              ) {
                subitem.permiso.valid = true;
              }
            }
          }
          // Elementos con valores heredados
          if (subitem.path == "_menu") {
            for (let item_menu of subitem.subitems) {
              // Validacion de permisos
              if (
                item_menu.permiso.type.includes("recepcion") &&
                this.$store.state.profesional.recepcion
              ) {
                item_menu.permiso.valid = true;
              }
              if (
                item_menu.permiso.type.includes("informes") &&
                this.$store.state.profesional.informes
              ) {
                item_menu.permiso.valid = true;
              }
              if (
                item_menu.permiso.type.includes("bloqueos") &&
                this.$store.state.profesional.bloqueos
              ) {
                item_menu.permiso.valid = true;
              }
              if (
                item_menu.permiso.type.includes("caja") &&
                this.$store.state.profesional.caja
              ) {
                item_menu.permiso.valid = true;
              }
              if (
                item_menu.permiso.type.includes("callc") &&
                this.$store.state.profesional.callc
              ) {
                item_menu.permiso.valid = true;
              }
            }
          }
        }
        return item;
      });
      rutas.forEach((item) => {
        let primary = {
          item: item.item,
          icon: item.icon,
          items: [],
        };

        for (let subitem of item.items) {
          if (subitem.path != "_menu") {
            if (subitem.permiso != null) {
              if (subitem.permiso.valid) {
                primary.items.push({
                  item: subitem.item,
                  path: subitem.path,
                });
              }
            }
          }
          if (subitem.path == "_menu") {
            let secondary = {
              item: subitem.item,
              path: subitem.path,
              subitems: [],
            };
            for (let item_menu of subitem.subitems) {
              if (item_menu.permiso.valid) {
                secondary.subitems.push({
                  item: item_menu.item,
                  path: item_menu.path,
                });
              }
            }
            if (secondary.subitems.length > 0) {
              primary.items.push(secondary);
            }
          }
        }
        if (primary.items.length > 0) {
          routes.push(primary);
        }
      });
      return routes;
    },
    router_search() {
      let routes = [];
      this.menu_valid.forEach((item) => {
        routes.push({ header: item.item });
        routes.push({ divider: true });
        for (let subitem of item.items) {
          if (subitem.path != "_menu") {
            routes.push({
              item: subitem.item,
              path: subitem.path,
            });
          } else {
            for (let item_menu of subitem.subitems) {
              routes.push({
                item: subitem.item + "/ " + item_menu.item,
                path: item_menu.path,
              });
            }
          }
        }
      });
      return routes;
    },
  },
};
</script>
