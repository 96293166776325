<template>
  <div class="form_component">
    <validation-observer :ref="list.ref">
      <v-row dense>
        <v-col v-for="(item, index) in list.list" :key="index" :cols="item.grid.cols" :md="item.grid.md"
          :sm="item.grid.sm">
          <!-- Campos validados -->
          <validation-provider v-if="item.validation.valid && item.show" v-slot="{ errors }"
            :name="typeof item.input === 'string' && item.input == 'select' ? item.select.label : item.input.label"
            :rules="item.validation.rules">
            <div v-if="typeof item.input === 'object'">
              <!-- Texfield -->
              <v-text-field v-if="item.input.type == 'input'" :label="item.input.label" :error-messages="errors"
                :type="item.input.input_type" v-model="item.model"></v-text-field>
              <!-- Texfield password -->
              <v-text-field v-else-if="item.input.type == 'input_password'"
                :append-icon="item.input.show ? 'mdi-eye-off' : 'mdi-eye'" :label="item.input.label"
                :error-messages="errors" :type="item.input.input_type" v-model="item.model" @click:append="
  item.input.show = !item.input.show;
item.input.input_type = item.input.show ? 'text' : 'password';
                "></v-text-field>
            </div>
            <div v-if="typeof item.input === 'string' && item.input == 'select'">
              <v-select v-model="item.model" :error-messages="errors" color="primary" :items="item.select.items"
                item-color="primary" :menu-props="{ maxHeight: '400' }" :label="item.select.label"
                :hint="item.select.hint" persistent-hint :multiple="item.select.multiple"></v-select>
            </div>
          </validation-provider>
          <!-- Fin campos validados -->
          <!-- Campos no validados -->
          <div v-if="!item.validation.valid && item.show">
            <div v-if="typeof item.input === 'object'">
              <!-- Texfield -->
              <v-text-field v-if="item.input.type == 'input'" :label="item.input.label" :type="item.input.input_type"
                v-model="item.model"></v-text-field>
              <!-- textarea -->
              <v-textarea v-if="item.input.type == 'textarea'" :label="item.input.label" v-model="item.model"
                :rows="item.input.rows"></v-textarea>
            </div>
            <div v-if="typeof item.input === 'string' && item.input == 'picker'">
              <!-- Picker -->
              <v-menu :ref="item.id" v-model="item.menu.model" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="item.model" :label="item.menu.label" prepend-inner-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="item.model" scrollable first-day-of-week="1" locale="es-co" color="primary"
                  @input="item.menu.model = false">
                  <!-- <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="item.menu.model = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs[item.id][0].save(item.model)"
                  >
                    Aceptar
                  </v-btn> -->
                </v-date-picker>
              </v-menu>
            </div>
            <!-- tipo switch -->
            <div v-if="typeof item.input === 'string' && item.input == 'switch'">
              <v-switch v-model="item.model" :label="item.name" color="primary" inset></v-switch>
            </div>
            <!-- Tipo select  -->
            <div v-if="typeof item.input === 'string' && item.input == 'select'">
              <v-select v-model="item.model" color="primary" :items="item.select.items" item-color="primary"
                :menu-props="{ maxHeight: '400' }" :label="item.select.label" :hint="item.select.hint" persistent-hint
                :multiple="item.select.multiple"></v-select>
            </div>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
import { required, email, min, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} es requerido",
});

extend("min", {
  ...min,
  message: "{_field_} no puede contener menos de {length} caracteres",
});

extend("max", {
  ...max,
  message: "{_field_} no puede contener mas de {length} caracteres",
});

extend("email", {
  ...email,
  message: "Email no valido",
});

export default {
  name: "formComponent",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ["list"],
  methods: {
    validate() {
      return this.$refs[this.list.ref].validate();
    },
    reset() {
      this.$refs[this.list.ref].reset();
    },
  },
};
</script>
