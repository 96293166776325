<template>
  <div class="Page_404">
    <v-row align-content="center" justify="center">
      <v-col sm="10" md="10">
        <h1>404 Page not found</h1>
      </v-col>
    </v-row>
  </div>
</template>
<script >
export default {
  name: "Page_404"
};
</script>