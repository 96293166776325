<template>
  <div class="login" :style="style">
    <v-row align-content="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card style="margin: 25% 0%" @keydown.enter.prevent="login()">
          <v-toolbar color="black" dark flat>
            <v-toolbar-title>Iniciar sesión</v-toolbar-title>
          </v-toolbar>
          <formComponent :list="form" ref="form_log" class="px-4 mt-4" />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="login()"
              >Acceder</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="alert" timeout="6000" color="error" top right>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="alert = false">
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import formComponent from "../components/Form.vue";
export default {
  name: "login",
  components: {
    formComponent,
  },
  data() {
    return {
      loading: false,
      alert: false,
      text: "",
      style: "",
      form: {
        ref: "login",
        list: [
          {
            grid: {
              cols: 12,
              md: 12,
              sm: 12,
            },
            input: {
              type: "input",
              input_type: "text",
              label: "Nit / Cc",
            },
            validation: {
              valid: true,
              rules: "required",
            },
            id: "user",
            model: "",
            show: true,
          },
          {
            grid: {
              cols: 12,
              md: 12,
              sm: 12,
            },
            input: {
              type: "input_password",
              input_type: "password",
              label: "Contaseña",
              show: false,
            },
            validation: {
              valid: true,
              rules: "required",
            },
            id: "password",
            model: "",
            show: true,
          },
        ],
      },
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.$refs.form_log.validate().then((res) => {
        if (res) {
          const data = this.$store.getters.validation_form(this.form.list);
          const body = {
            route: "/login_rec",
            data: data,
          };
          this.$store
            .dispatch("login", body)
            .then((response) => {
              if (response.data.success) {
                this.$store
                  .dispatch("setToken", response.data.data)
                  .then((res_token) => {
                    if (res_token) {
                      this.$router.push("/home");
                    }
                  });
              }
            })
            .catch((error) => {
              this.text = error.response.data.data;
              this.alert = true;
            })
            .finally(()=>(this.loading = false))
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch("loadNav", false);

    let rutas = [
      "art-nouveau-2388453_1920.jpg",
      "barber-1386208_1920.jpg",
      "barber-1453064_1920.jpg",
      "beard-2326422_1920.jpg",
      "bicycle-3045580_1920.jpg",
      "cat-1045782_1920.jpg",
      "girl-3964272_1920.jpg",
      "people-3963704_1920.jpg",
      "poseidon-1621062_1920.jpg",
      "barber-1017457_1920.jpg",
      "barber-2345701_1920.jpg",
      "beauty-salon-3277314_1920.jpg",
      "cat-3641340_1920.jpg",
      "drinks-2847366_1920.jpg",
      "classic-car-1209334_1920.jpg",
      "expocosmetica-1312191_1920.jpg",
      "motorcycles-3045706_1920.jpg",
      "architecture-1868706_1920.jpg",
      "audience-945449_1920.jpg",
      "bridge-53769_1920.jpg",
      "clouds-3970938_1920.jpg",
      "concert-768722_1920.jpg",
      "mountain-3969664_1920.jpg",
      "mt-fuji-1346096_1920.jpg",
      "people-3968539_1920.jpg",
      "tori-1976609_1920.jpg",
    ];

    let aleatorio = Math.round(Math.random() * 25);

    let image = "https://luxcultury.com/luxcultury/login/" + rutas[aleatorio];

    this.style =
      "background-image: url(" +
      image +
      "); background-size: cover; background-position:center; width: 100%; \
      height: 100%; overflow: hidden; overflow-y: scroll;";
  },
};
</script>
