import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";

Vue.config.productionTip = false;
// axios.defaults.baseURL = "http://localhost:8089";
axios.defaults.baseURL = "https://old-serve.luxcultury.com/";
window.axios = axios;

Vue.filter("currency", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter("folio", (value) => {
  if (value == null) {
    return "";
  }
  value = parseInt(value);

  if (value < 10) {
    return "000" + value;
  } else if (value < 100) {
    return "00" + value;
  } else if (value < 1000) {
    return "0" + value;
  } else {
    return value;
  }
});

Vue.filter("utility", (value) => {
  if (value == null) {
    return "";
  }
  return value.toFixed(2) + "%";
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
