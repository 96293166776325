<template>
  <v-app>
    <Menu v-if="nav" />
    <v-main style="background-color: #f2f3f7!important">
      <router-view v-on:access="access($event)" />
    </v-main>
  </v-app>
</template>

<script>
import Menu from "./components/Menu";

export default {
  name: "App",

  components: {
    Menu,
  },
  data: () => ({}),
  methods: {},
  mounted() {
    // let token = this.$store.state.access_token;
    // if (token != null) {
    //   token = atob(token.access_token).split("-_-")[0];
    //   token = token.substring(0, token.length - 1);
    // }
    // let pusher = new Pusher("0f3262642acbea6b807e", {
    //   cluster: "us2",
    // });
    // let channel = pusher.subscribe("close_token." + token);
    // channel.bind("App\\Events\\SessionCloseToken", function(response) {
    //   localStorage.removeItem("access_token");
    //   window.location.reload();
    // });
  },
  computed: {
    nav() {
      return this.$store.state.nav;
    },
  },
  watch: {},
};
</script>
