import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";
import Page_404 from "../views/404_page.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/agenda/home.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/home",
    component: () => import("../views/agenda/home.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/clientes",
    component: () => import("../views/agenda/clientes.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/tomarCita",
    name: "tomarCita",
    component: () => import("../views/agenda/tomarCita.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/privada",
    name: "privada",
    component: () => import("../views/agenda/privada.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/bloqueos",
    component: () => import("../views/agenda/bloqueos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/agenda",
    name: "agenda",
    component: () => import("../views/agenda/agenda.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/agendaCompleta",
    name: "agendaCompleta",
    component: () => import("../views/agenda/completa.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/bonoRegalo",
    name: "bonoRegalo",
    component: () => import("../views/agenda/bonoregalo.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/print_bono/:id",
    name: "print_bono",
    component: () => import("../views/agenda/bono/pos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },

  {
    path: "/anticipos",
    name: "anticipos",
    component: () => import("../views/agenda/anticipos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/print_anticipo/:id",
    name: "print_anticipo",
    component: () => import("../views/agenda/anticipos/pos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/servicios",
    name: "servicios",
    component: () => import("../views/agenda/servicios.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/productos",
    name: "productos",
    component: () => import("../views/productos/productos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/pagarCita/:cita",
    name: "pagarCita",
    component: () => import("../views/agenda/ventas/pagoCita.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/otrasVentas",
    name: "otrasVentas",
    component: () => import("../views/agenda/ventas/otrasVentas.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/credito",
    name: "credito",
    component: () => import("../views/agenda/ventas/credito.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/pos/:id",
    name: "pos",
    component: () => import("../views/reportes/factura/pos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/facturas",
    name: "facturas",
    component: () => import("../views/reportes/facturas.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/detallefactura/:id",
    name: "detallefactura",
    component: () => import("../views/reportes/factura/detalle.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/detalleNotaCredito/:id",
    name: "detalleNotaCredito",
    component: () => import("../views/reportes/factura/notaCredito.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/facturasPendientes",
    name: "facturasPendientes",
    component: () => import("../views/reportes/facturasPendientes.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/visitaPendiente",
    name: "visitaPendiente",
    component: () => import("../views/reportes/visitar.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/historialVisitasClientes",
    name: "historialVisitasClientes",
    component: () => import("../views/reportes/historialVisitas.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/documentos_electronicos",
    name: "documentos_electronicos",
    component: () => import("../views/reportes/documentosElectronicos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/abonosFacturas",
    name: "abonosFacturas",
    component: () => import("../views/agenda/abonos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/detalleAbonosFactura/:id",
    name: "detalleAbonosFactura",
    component: () => import("../views/agenda/abonos/detalleAbonosFactura.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/ordenes",
    name: "ordenes",
    component: () => import("../views/productos/ordenes.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/orden",
    name: "orden",
    component: () => import("../views/productos/orden/orden.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/detalleOrden/:id",
    name: "detalleOrden",
    component: () => import("../views/productos/orden/detalle.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/abonosOrden",
    name: "abonosOrden",
    component: () => import("../views/productos/abonos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/detalleAbonos/:id",
    name: "detalleAbonos",
    component: () => import("../views/productos/abonos/detalleabonos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/movimientosStock",
    name: "movimientosStock",
    component: () => import("../views/productos/movimiento_stock.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/cierreInventarios",
    name: "cierreInventarios",
    component: () => import("../views/productos/cierre.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/detalleCierre/:date/:stock",
    name: "detalleCierre",
    component: () => import("../views/productos/cierre/detalle.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/stockVentas",
    name: "stockVentas",
    component: () => import("../views/productos/stock/venta.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/stockConsumos",
    name: "stockConsumos",
    component: () => import("../views/productos/stock/consumo.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/liquidacion",
    name: "liquidacion",
    component: () => import("../views/caja/liquidacion.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path:
      "/print_liquidacion/:profesional_id/:inicio/:fin/:data/:propina/:deduccion/:prestamos",
    name: "print_liquidacion",
    component: () => import("../views/caja/liquidacion/pos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/gastos",
    name: "gastos",
    component: () => import("../views/caja/gastos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/print_gasto/:id",
    name: "print_gasto",
    component: () => import("../views/caja/gasto/pos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/abonoPrestamos",
    name: "abonoPrestamos",
    component: () => import("../views/caja/abonos/abonos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/detalleAbonoPrestamo/:id",
    name: "detalleAbonoPrestamo",
    component: () => import("../views/caja/abonos/detalleAbonoPrestamo.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/print_abono_prestamo/:id",
    name: "print_abono_prestamo",
    component: () => import("../views/caja/abonos/pos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/cuadrecajaAc",
    name: "cuadrecajaAc",
    component: () => import("../views/reportes/cuadrecajaAc.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/cuadreCaja",
    name: "cuadreCaja",
    component: () => import("../views/reportes/cuadrecaja.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/detalleCaja/:id/:inicio/:fin",
    name: "detalleCaja",
    component: () => import("../views/reportes/cuadre/detalle.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/print_cierre/:id",
    name: "print_cierre",
    component: () => import("../views/reportes/cuadre/pos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/historial_citas",
    name: "historial_citas",
    component: () => import("../views/reportes/historialCitas.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/historial_clientes",
    name: "historial_clientes",
    component: () => import("../views/reportes/historialClientes.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/detalle_cliente_historial/:cliente_id",
    name: "detalle_cliente_historial",
    component: () => import("../views/reportes/cliente/detalleHistorial.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/informe_por_producto",
    name: "informe_por_producto",
    component: () => import("../views/reportes/productosPorPrducto.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/informe_productos_venta",
    name: "informe_productos_venta",
    component: () => import("../views/reportes/productosVenta.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/informe_productos_consumo",
    name: "informe_productos_consumo",
    component: () => import("../views/reportes/productosConsumo.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/notas_credito",
    name: "notas_credito",
    component: () => import("../views/reportes/notasCredito.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/devoluciones",
    name: "devoluciones",
    component: () => import("../views/reportes/devoluciones.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/info-servicios",
    name: "info-servicios",
    component: () => import("../views/reportes/servicios.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/info-frecuentes",
    name: "info-frecuentes",
    component: () => import("../views/reportes/frecuentes.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/graficas",
    name: "graficas",
    component: () => import("../views/reportes/grafica.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/vendedores",
    name: "vendedores",
    component: () => import("../views/reportes/vendedores.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/puntos",
    name: "puntos",
    component: () => import("../views/reportes/puntos.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/cumple",
    name: "cumple",
    component: () => import("../views/interacciones/cumpleaños.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/cambio_sede",
    name: "cambio_sede",
    component: () => import("../views/cambio/cambio.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/sesiones",
    name: "sesiones",
    component: () => import("../views/sesiones/sesiones.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/guias",
    name: "guias",
    component: () => import("../views/guia/guia.vue"),
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token == null) {
        next(login);
      }
      next();
    },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    beforeEnter(to, from, next) {
      let access_token = localStorage.getItem("access_token") || null;
      if (access_token != null) {
        next("/home");
      }
      next();
    },
  },
  {
    path: "/loginClientes/:data",
    name: "loginClientes",
    component: () => import("../views/cliente_web/login_cliente.vue"),
  },
  {
    path: "/registerClientes/:data",
    name: "registerClientes",
    component: () => import("../views/cliente_web/register_cliente.vue"),
  },
  {
    path: "/agendarCliente/:id/:data",
    name: "agendarCliente",
    component: () => import("../views/cliente_web/agendar.vue"),
  },
  {
    path: "*",
    component: Page_404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
